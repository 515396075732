import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import * as aboutStyles from './about.module.css'

const About = () => {
    const data = useStaticQuery(query)
    const pics = data.allContentfulAbout.nodes
    const jass = getImage(pics[0].profilePic)
    const ukm = getImage(pics[1].profilePic)
    return (
        <Layout>
            <SEO title='Meist | Poolusfilm' description='Meie lugu' />
            <Hero name='MEIST' imgurl='meistHero' imgpos='0px -300px'/>
            <main className={aboutStyles.page}>
                <section className={aboutStyles.introduction}>
                    <h3>Miks Poolusfilm?</h3>
                    <p>
                        Oleme kaheliikmeline videoproduktsiooni ettevõte, kes pakub kvaliteetseid ja kliendisõbralike videoteenuseid alates reklaamvideote produtseerimisest kuni üritusvideote filmimise ja monteerimiseni. 
                        Oleme Poolusfilmi nime all tegutsenud kaks aasat, kuid videoproduktsiooniga alustasime tegevust juba 2015 aastal, olles ise veel vaid gümnaasiumiõpilased.  
                        Aastal 2018 otsustasime astuda suure sammu edasi ning asutada omaenda videoproduktsiooni ettevõtte, mille nimeks sai Poolusfilm. 
                        Selle kahe tegutsemisaasta vältel oleme omandanud tohutult kogemusi ning teinud koostööd mitmete ägedate ettevõtetega nagu CV-Online, Tartu Ülikool, IVEK, Contriber, Thomas International, Adventures.ee, Smoked Sparrow ja paljud teised. 
                    </p>
                    <p>
                        Meie missiooniks on pakkuda kvaliteetset, kuid taskukohast videoproduktsiooni nii väikeettevõtetele, suurettevõtetele kui ka eraisikutele. 
                        Kliendi soovid on meie prioriteet, kuid alati oleme valmis ka ise klienti nõustama, et jõuda tema nägemuses oleva lõpptulemuseni. 
                        Kui soovid tõsta oma ettevõtte kujundi järgmisele tasemele läbi videoturunduse, 
                        tahad lihtsalt ägedat aftermoviet oma üritusest või otsid üldse midagi kolmandat, siis ära karda meiega ühendust võtta - leiame kindlasti koos lahenduse Teie probleemile!
                    </p>
                </section>
                <section className={aboutStyles.team}>
                    <div className={aboutStyles.member1}>
                        <GatsbyImage image={jass} alt='' style={{overflow: 'unset'}}/> 
                        <h3>JASPER KÄÄNIK</h3>
                        <p>PRODUTSENT, OPERAATOR</p>
                    </div>
                    <div className={aboutStyles.member2}>
                        <GatsbyImage image={ukm} alt='' style={{overflow: 'unset'}}/> 
                        <h3>KARL MARTIN UUSTALU</h3>
                        <p>PRODUTSENT, MONTEERIJA</p>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
  {
    allContentfulAbout {
      nodes {
        profilePic {
            title
          gatsbyImageData(width: 250, height: 250, placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`

export default About